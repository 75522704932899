



























































































import { PendingReservationResponse } from '@/composables/useBookedResources/types';
import { defineComponent, PropType, ref } from '@/plugins/composition';
//import { routeNames } from '@/router/routeNames';
import { useCreatingBooking} from '@/composables/useCreatingBooking';
import { formatBookingPrice } from '@/utils/number';
import { formatDate, formatTimeRange, formatRepeatingTimeRange } from '@/utils/date';
import { useApi } from '@/composables/useApi';
import { useLoader } from '@/composables/useLoader';

export default defineComponent({
  name: 'ReservationItem',
  props: {
    reservation: {
        type: Object as PropType<PendingReservationResponse>,
        required: true,
    },
    itemIndex: {
        type: Number,
        required: true
    },
    showDeleteBtn: {
        type: Boolean,
        required: false
    },
    showBookingPrice: {
        type: Boolean,
        default: false,
        required: false
    }
  },
  setup(props, {emit}) {
    const booking = useCreatingBooking();
    const collapsed = ref(false);
    const { loading, withLoader } = useLoader({ initial: true });
    const overlay = ref(false);

    const expandOrCollapse = () => {
        collapsed.value = !collapsed.value;
    };

    const cancel = async () => {
      const { $delete } = useApi();
      overlay.value = true;
      withLoader(async () => {
        await $delete(`/reservations/${props.reservation.uuid}`);

        emit('deleted');
        overlay.value = false;
      });
      
    };

    return {
        ...booking,
        formatDate,
        formatTimeRange, 
        formatRepeatingTimeRange,
        collapsed,
        expandOrCollapse,
        cancel,
        formatBookingPrice,
        loading,
        overlay
    };
  }
});
