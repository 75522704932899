



























































import BaseButton from '@/components/base/BaseButton.vue';
import { useCreatingBooking, currentBooking, calendarSelection } from '@/composables/useCreatingBooking';
import { defineComponent, PropType, ref, onMounted } from '@/plugins/composition';
import { formatDate, formatTimeRange, formatRepeatingTimeRange } from '@/utils/date';
import { PendingReservationResponse } from '@/composables/useBookedResources/types';
import ReservationItem from './ReservationItem.vue';
import { useRouter } from '@/router/useRouter';


import { getPendingReservations } from '@/composables/useBookedResources/useBookedReservations';

import { PriceResult } from '@/composables/useBookedResources/types';
import { formatBookingPrice, formatPrice } from '@/utils/number';

import { useLoader } from '@/composables/useLoader';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  components: { BaseButton, ReservationItem },
  name: 'ReviewBookingCard',
  props: {
    reservation: Object as PropType<PendingReservationResponse>,
  },

  setup() {
    console.log('----------- setup ReviewBookingCard -------');
    const reservations = ref<PendingReservationResponse[]>([]);
    const booking = useCreatingBooking();
    const loading = ref(false);
    const dates = ref<{ start: Date; end: Date }[]>([]);
    const price = ref<PriceResult | null>(null);
    const loadReservations = ref(false);
    const { withLoader } = useLoader({ initial: true });
    const total = ref(0);
    const totalDiscount = ref(0);
    const { router } = useRouter();

    const expanded = ref(true);

    const fetchReservations = async () =>
        withLoader(async () => {
            loading.value = true;
            reservations.value = await getPendingReservations();
            localStorage.setItem('numberBookingItems', reservations.value.length.toString());
            window.dispatchEvent(new CustomEvent('number-booking-changed', {
                detail: {
                storage: localStorage.getItem('numberBookingItems')
                }
            }));
            loading.value = false;
            total.value = calculateTotal();
            totalDiscount.value = calculateTotalDiscount();
        });


    const refresh = async () => {
        await fetchReservations();
      };
  
      onMounted(async () => {
        await fetchReservations();
      });
    const calculateTotal = () => {
        let totalPrice = 0;
        
         reservations.value.forEach(reservation => {
            totalPrice += (reservation.totalPrice as number);
         });
        return totalPrice;
      }
    const calculateTotalDiscount = () => {
        let basePrice = 0;
         reservations.value.forEach(reservation => {
            basePrice += (reservation.baseRate * reservation.duration);
         });
        return basePrice  - total.value;
    }

    const goToBookings = () => {
      router.push({ name: routeNames.spaces.book });
    };

    return {
      formatTimeRange,
      formatDate,
      ...booking,
      loading,
      dates,
      price,
      currentBooking,
      calendarSelection,
      formatBookingPrice,
      formatPrice,
      formatRepeatingTimeRange,
      loadReservations,
      reservations,
      expanded,
      refresh,
      total,
      totalDiscount,
      goToBookings
    };
  },
  methods: {
    payNow() {
      this.$emit('request-pay');
    },

    loadData() {
        this.loadReservations = true;
    }
    // formatTimeRange(start: Date | string, end: Date | string, timeOnly = false) : string | null {
    //     return formatDate(start, timeOnly ? 'hh:mmaaa' : 'EEE dd/MM, hh:mmaaa') + ' - ' + formatDate(end, 'hh:mmaaa');
    // }
  },
});
