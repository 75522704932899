












































































































































import { useApi } from '@/composables/useApi';
import { useAuthStore } from '@/composables/useAuthStore';
//import { PendingReservation } from '@/composables/useBookedResources/types';
import { getPendingReservations } from '@/composables/useBookedResources/useBookedReservations';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { defineComponent, onMounted, ref, PropType } from '@/plugins/composition';
import ReservationItem from './ReservationItem.vue';
import { formatBookingPrice } from '@/utils/number';
import { routeNames } from '@/router/routeNames';
import BaseButton from '@/components/base/BaseButton.vue';
import { useLoader } from '@/composables/useLoader';
import { usePayments } from '@/composables/usePayments';
import { PaymentMethod } from '@stripe/stripe-js';
import { useRouter } from '@/router/useRouter';
import { useConfirmation } from '@/composables/useConfirmation';
import PaymentMethods from '@/views/ProfilePage/components/PaymentMethods.vue';
import { PendingReservationResponse } from '@/composables/useBookedResources/types';

type Payment = {
  reference: string;
  total: number;
};

export default defineComponent({
  components: { MemberPage, ReservationItem, BaseButton, PaymentMethods },
  name: 'CheckoutCard',
  props: {
    reservation: Object as PropType<PendingReservationResponse>,
  },
  setup(props) {
      console.log('------------ Checkout Card ------------');
    const reservations = ref<PendingReservationResponse[]>([]); //[props.reservation];
    const total = ref(0);
    const defaultPaymentMethod = ref<PaymentMethod | null>(null);
    const payment = ref<Payment | null>(null);
    const dialog = ref(false);
    const paymentError = ref<string | null>(null);
    const addingPaymentMethod = ref(false);

    const { post } = useApi();
    const { user } = useAuthStore();
    const { loading, withLoader } = useLoader({ initial: true });

    const { getDefaultPaymentMethod } = usePayments();
    const { router } = useRouter();
    const { confirmation } = useConfirmation();
    const expanded = ref(true);
    const agreeTerm = ref(false);
    const reservationSaving = ref(0);
    const fetchDefaultPaymentMethod = async () => 
      withLoader(async () => {
              
              defaultPaymentMethod.value = await getDefaultPaymentMethod();

              
          });

    const fetchReservations = async () =>
      withLoader(async () => {
        reservations.value = await getPendingReservations();
        localStorage.setItem('numberBookingItems', reservations.value.length.toString())
        window.dispatchEvent(new CustomEvent('number-booking-changed', {
          detail: {
            storage: localStorage.getItem('numberBookingItems')
          }
        }));
        total.value = calculateTotal();
        reservationSaving.value = calculateTotalDiscount();
      });

    const pay = async () =>
      withLoader(async () => {
        await user.value
          .makePayment()
          .catch((error) => {
            if (error.response.data.redirect) {
              window.location.href = error.response.data.redirect;
            } else {
              paymentError.value = error.response.data.message;
            }
          })
          .then((res) => {
            if (res.total) {
              dialog.value = true;
              payment.value = res;
              localStorage.setItem('numberBookingItems', '0');
              window.dispatchEvent(new CustomEvent('number-booking-changed', {
                  detail: {
                  storage: localStorage.getItem('numberBookingItems')
                  }
              }));
            }
          });
      });

    const goToBookings = () => {
      router.push({ name: routeNames.my.bookings });
    };

    const refresh = async () => {
      await fetchReservations();
    };

    onMounted(async () => {
      await fetchReservations();
      await fetchDefaultPaymentMethod();
    });

    const calculateTotal = () => {
      let totalPrice = 0;
      
       reservations.value.forEach(reservation => {
          totalPrice += (reservation.totalPrice as number);
       });
      return totalPrice;
    };

    const calculateTotalDiscount = () => {
        let basePrice = 0;
        reservations.value.forEach(reservation => {
            basePrice += (reservation.baseRate * reservation.duration);
        });
        return basePrice  - total.value;
    };

    //const total = props.reservation.totalPrice;

    const payByInvoice = async () => {
      const text =
        'To request a booking and to pay by invoice, you will need to delete the booking you have just made and contact <a href="mailto:bookings@brandx.org.au">bookings@brandx.org.au</a> with your booking request (including location, date, time, billing name and address). <br><br> Please allow 48 hours for Brand X to confirm your request, which will be subject to availability at the time we answer your email.';
      const confirm = await confirmation(text, { confirmTitle: 'Are you sure?', confirmText: 'Delete Bookings' });

      if (confirm) {
        withLoader(async () => {
          const response = await post('empty-cart', {});
          if (response) {
            router.push({ name: routeNames.my.bookings });
          }
        });
      }
    };

    const methodAdded = async () => {
      fetchDefaultPaymentMethod();
      addingPaymentMethod.value = false;
    };

    const addPaymentMethod = () => {
      addingPaymentMethod.value = true;
    };

    //console.log('------------ reservations ----------', total);

    return {
     
      routeNames,
      formatBookingPrice,
      loading,
      total,
      pay,
      defaultPaymentMethod,
      payment,
      dialog,
      goToBookings,
      refresh,
      paymentError,
      payByInvoice,
      user,
      addingPaymentMethod,
      addPaymentMethod,
      methodAdded,
      reservations,
      expanded,
      agreeTerm,
      reservationSaving
    };
  },

});
