







































import BaseButton from '@/components/base/BaseButton.vue';
import { defineComponent, ref } from '@/plugins/composition';
//import { routeNames } from '@/router/routeNames';
//import { useRouter } from '@/router/useRouter';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import AboutBookingCard from './components/AboutBookingCard.vue';
import CheckoutCard from './components/CheckoutCard.vue';
import ReviewBookingCard from './components/ReviewBookingCard.vue';
import { PendingReservationResponse } from '@/composables/useBookedResources/types';
//import { isBefore, parseISO } from 'date-fns';

export default defineComponent({
  components: { MemberPage, BaseButton, AboutBookingCard, ReviewBookingCard, CheckoutCard },
  name: 'CheckoutWorkflowPage',
  props: {
    step: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      currentStep: 1,
      reservation: {},
      isDisabled: true,
      payTabDisabled: true
    };
  },

  setup(props) {
    const tab = ref(0);

    if(props.step == 'checkout') {
      tab.value = 2;
    }

    return {
      tab,
    };
  },

  watch: {
    tab: function() {
      if(this.tab >= 1) {
        this.payTabDisabled = false;
      }
    }
  },

  methods: {
    handleCreatedReservation(reservation: PendingReservationResponse) {
      console.log('---------- handleCreatedReservation ---------');
      this.currentStep = 2;
      this.tab = 1;
      this.reservation = reservation;
      console.log('reservation: ', reservation);
    },
    payRequest() {
      console.log('--------- request pay --------');
      this.tab = 2;
    },
  },
});
