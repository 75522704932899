




























































































































































import BaseButton from '@/components/base/BaseButton.vue';
import { useCreatingBooking, currentBooking, calendarSelection } from '@/composables/useCreatingBooking';
import { defineComponent, onMounted, onUnmounted, ref } from '@/plugins/composition';
import { formatTimeRange } from '@/utils/date';
import { routeNames } from '@/router/routeNames';
import { useApi } from '@/composables/useApi';
import { debouncedWatch } from '@vueuse/core';
import { PriceResult, PendingReservationResponse } from '@/composables/useBookedResources/types';
import QuoteCard from './QuoteCard.vue';
import { createReservation, createRepeatingReservation, getPendingReservations } from '@/composables/useBookedResources/useBookedReservations';
import { useAuthStore } from '@/composables/useAuthStore';
import { useRouter } from '@/router/useRouter';
import { formatISO } from 'date-fns';
import { rules } from '@/composables/useValidation/validations';
import { Resource } from '@/models/booked/Resource';
import { useBookedResources } from '@/composables/useBookedResources';
import { AccountActivityResult } from '@/composables/useCreatingBooking/types';
import { ResourceResponse } from '@/composables/useApi/types';
import { Activity } from '@/models/booked/Activity';
import { useLoader } from '@/composables/useLoader';
import ReservationItem from './ReservationItem.vue';
//import {defineEmits} from'vue';

type DatePair = {
  start_time: string;
  end_time: string;
};

export default defineComponent({
  components: { BaseButton, QuoteCard, ReservationItem },
  name: 'AboutBookingCard',
  // props: {
  //   reservation: Object as PropType<PendingReservationResponse>,
  // },
  // emits: {
  //   'created-reservation': (reservation: PendingReservationResponse) => true,
  // },
  data() {
    return {
      accountTypes: [
        { text: 'Individual Artist can be omitted for the demo', value: 'Individual artist' },
        { text: 'Arts Organisation', value: 'Arts Organisation' },
        { text: 'General public', value: 'General public' },
      ],
    };
  },
  setup() {
    const price = ref<PriceResult | null>(null);
    const booking = useCreatingBooking();
    const { loading, withLoader } = useLoader({ initial: true });
    const valid = ref(false);
    const success = ref(false);
    const error = ref(false);
    const errorMessage = ref('');
    const dates = ref<{ start: Date; end: Date }[]>([]);
    const resource = ref<null | Resource>(null);
    const reservations = ref<PendingReservationResponse[]>([]);
    const { getResource } = useBookedResources();
    const terms = ref(false);
    const activities = ref<Activity[]>([]);
    const reservationSaved = ref<PendingReservationResponse>();

    const expanded = ref(false)

    const { user } = useAuthStore();

    console.log('User: ', user);

    const { post } = useApi();
    const { get } = useApi();

    const { router } = useRouter();

    const getActivities = async () => {
      const response = await get<ResourceResponse<AccountActivityResult>>(
        '/user/account-type/' + user.value.data.accountTypeId + '/activities'
      );
      console.log('Response: ', response);
      
      return response.data.activities;
    };

    const goToBookings = () => {
      router.push({ name: routeNames.spaces.book });
    };

    const fetchReservations = async () => {
      reservations.value = await getPendingReservations();
      localStorage.setItem('numberBookingItems', reservations.value.length.toString())
      window.dispatchEvent(new CustomEvent('number-booking-changed', {
        detail: {
          storage: localStorage.getItem('numberBookingItems')
        }
      }));
    }

    const getRepeatingQuote = async () => {
      return await post<PriceResult>('/reservations/repeating/quote', {
        start_date: formatISO(booking.repeatingOptions.value.startDate),
        end_date: formatISO(booking.repeatingOptions.value.endDate),
        start_time: formatISO(booking.repeatingOptions.value.startTime),
        end_time: formatISO(booking.repeatingOptions.value.endTime),
        interval_type: booking.repeatingOptions.value.intervalType,
        interval: booking.repeatingOptions.value.interval,
        weekly_days: booking.repeatingOptions.value.days,
        generatingIncome: currentBooking.generatingIncome,
        funded: currentBooking.funded,
        performance: currentBooking.performance,
        attendees: currentBooking.attendees,
      });
    };

    const getNonRepeatingQuote = async () => {
      return await post<PriceResult>('/reservations/quote', {
        start: calendarSelection.startSlot?.startDateTime,
        end: calendarSelection.endSlot?.endDateTime,
        generatingIncome: currentBooking.generatingIncome,
        funded: currentBooking.funded,
        performance: currentBooking.performance,
        attendees: currentBooking.attendees,
        accountType: currentBooking.accountType,
        volume: currentBooking.volume,
        describeActivity: currentBooking.describeActivity,
      });
    };

    const getDates = async () => {
      const _dates = await post<DatePair[]>('/reservations/repeating/dates', {
        start_date: formatISO(booking.repeatingOptions.value.startDate),
        end_date: formatISO(booking.repeatingOptions.value.endDate),
        start_time: formatISO(booking.repeatingOptions.value.startTime),
        end_time: formatISO(booking.repeatingOptions.value.endTime),
        interval_type: booking.repeatingOptions.value.intervalType,
        interval: booking.repeatingOptions.value.interval,
        weekly_days: booking.repeatingOptions.value.days,
        generatingIncome: currentBooking.generatingIncome,
        funded: currentBooking.funded,
        performance: currentBooking.performance,
        attendees: currentBooking.attendees,
      });

      dates.value = _dates.map((pair: DatePair) => ({
        start: new Date(pair.start_time),
        end: new Date(pair.end_time),
      }));
    };

    onMounted(async () => {
      withLoader( async () => {
        if (!booking.currentResource.value) {
          router.push({ name: routeNames.spaces.book });
        }
        if (booking.isRepeating.value) {
          await getDates();
        }
        const id = booking.currentResource.value?.id;
        if(id) {
          resource.value = await getResource(id);
        }
        
        await fetchReservations();

        if(user.value.data.member && user.value.data.customAttributes.account_type != 'General_Public') {
          activities.value = await getActivities();
        }

      });
    });

    const calculatePrice = async () => {
      const result = booking.isRepeating.value ? await getRepeatingQuote() : await getNonRepeatingQuote();

      price.value = result;
      setTimeout(() => {
        loading.value = false;
      }, 250);
    };

    const confirm = async () => {
      withLoader( async () => {
        if (booking.isRepeating.value) {
          return await confirmRepeatingBookings();
        } else {
          return await confirmBooking();
        }
      });
    };

    //const { emit } = defineEmits();

    const confirmBooking = async () => {
      const reservation = await createReservation(
        {
          start: calendarSelection.startSlot?.startDateTime as unknown as Date,
          end: calendarSelection.endSlot?.endDateTime as unknown as Date,
          description: currentBooking.description as string,
          income: currentBooking.generatingIncome,
          funding: currentBooking.funded,
          performance: currentBooking.performance,
          activity_code: currentBooking.describeActivity,
          haveEquipment: currentBooking.haveEquipment,
          equipment: currentBooking.equipment,
          attendees: currentBooking.attendees as number,
        },
        user.value.uuid,
        booking.currentResource.value?.id as number,
        booking.currentResource.value?.name as string
      ).catch(() => {
        loading.value = false;
        error.value = true;

        errorMessage.value =
          'Something went wrong when creating your booking. Some availabilities may have changed. Please edit your booking and try again.';
      });

      if (reservation) {
        //context.emit('created-reservation', reservation);
        reservationSaved.value = reservation;
        success.value = true;
        loading.value = false;
        console.log('created-reservation: ', reservation);
        //const reservation =
        
      }
    };

    const confirmRepeatingBookings = async () => {
      const response = await createRepeatingReservation(
        {
          start: booking.repeatingOptions.value.startDate,
          end: booking.repeatingOptions.value.endDate,
          description: currentBooking.description as string,
          attendees: currentBooking.attendees as number,
          income: currentBooking.generatingIncome,
          funding: currentBooking.funded,
          performance: currentBooking.performance,
          start_time: formatISO(booking.repeatingOptions.value.startTime),
          end_time: formatISO(booking.repeatingOptions.value.endTime),
          interval_type: booking.repeatingOptions.value.intervalType,
          interval: booking.repeatingOptions.value.interval,
          weekly_days: booking.repeatingOptions.value.days,
          activity_code: currentBooking.describeActivity,
          haveEquipment: currentBooking.haveEquipment,
          equipment: currentBooking.equipment
        },
        user.value.uuid,
        booking.currentResource.value?.id as number,
        booking.currentResource.value?.name as string
      ).catch(() => {
        loading.value = false;
        valid.value = true;
        error.value = true;
        errorMessage.value =
          'Something went wrong when creating your booking. Some availabilities may have changed. Please edit your booking and try again.';
      });

      if (response) {
        //context.emit('created-reservation', response);
        reservationSaved.value = response;
        loading.value = false;
        success.value = true;
        
      }
    };

    onUnmounted(() => {
      booking.resetCurrentBooking();
      booking.resetCalendar();
    });

    debouncedWatch(
      currentBooking,
      () => {
        if (valid.value) {
          //loading.value = true;
          //calculatePrice();
        }
      },
      { debounce: 500 }
    );

    const capacityRule = (v: number) => {
      const cap = resource.value?.capacity as string;
      if (!cap) {
        return true;
      }

      return v <= parseInt(cap) || 'The limit for this resource is ' + cap;
    };

    return {
      formatTimeRange,
      ...booking,
      routeNames,
      price,
      loading,
      valid,
      confirm,
      success,
      goToBookings,
      error,
      errorMessage,
      currentBooking,
      calendarSelection,
      rules,
      dates,
      activities,
      terms,
      capacityRule,
      user,
      reservationSaved,
      reservations,
      expanded
    };
  },

  methods: {
    payNow() : boolean 
    {
      this.success = false;
      this.$emit('created-reservation', this.reservationSaved);
      return true;
    }
  }
});
